import { Component, Input, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { AbstractControl, ValidatorFn, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';

import { LoaderService } from '@watchguard/wg-loader';
import { WgDropdownItem } from '@watchguard/wg-dropdown';

import { ADD_NEW_ACC_STRINGS, APP_TITLE_STRINGS, ERROR_STRINGS } from '../shared/ui-strings';
import { AccountManagerService } from '../account-manager.service';
import { CustomValidatorService } from '../shared/custom-validator.service';
import { SetPassword, ApiResponse, TranslationParam, InviteFromSalesforce } from '../shared/model';
import { CommonHelperService } from '../shared/common-helper.service';


@Component({
  selector: 'wgc-select-region',
  templateUrl: './select-region.component.html'
})
export class SelectRegionComponent implements OnInit, OnDestroy{

  @Input() password: string;
  @Input() token = '';
  @Input() payload: InviteFromSalesforce;

  showConfirmation = false;
  showConfirmRegionSelection = false;
  signInUrl = '';
  form: UntypedFormGroup;

  compStrs = ADD_NEW_ACC_STRINGS;
  errorStrs = ERROR_STRINGS;
  titleStrs = APP_TITLE_STRINGS;
  pageHeading = this.compStrs.create_acc_heading;

  regionSelected: WgDropdownItem;
  regionsList: WgDropdownItem[] = [
    { id: 1, val: 'America' },
    { id: 2, val: 'Europe' },
    { id: 3, val: 'Asia Pacific' }
  ];

  confirmLblTransParams: TranslationParam = { selected_region: '' };
  matchRegionValFn: ValidatorFn = this.customValidator.commonValFn('region_mismatch');

  setPasswordSub: Subscription;
  inviteFromSfSub: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private customValidator: CustomValidatorService,
    private accountManagerService: AccountManagerService,
    private commonHelperService: CommonHelperService,
    private loaderService: LoaderService,
    private el: ElementRef) {
  }

  ngOnInit(): void {
    this.commonHelperService.setTitle(this.titleStrs.select_region);
    this.initForm();
    this.signInUrl = this.commonHelperService.getUrlByEnv('signIn');
  }

  initForm(): void {
    this.form = this.fb.group({
      region: [
        '',
        [
          this.customValidator.requiredFn('region')
        ]
      ],
      confirm_region: [
        '',
        [
          this.customValidator.requiredFn('confirm_region')
        ]
      ]
    });
  }

  regionDropDownSelected(item: WgDropdownItem): void {
    this.regionSelected = item;
    this.confirmLblTransParams = { selected_region: item.val };
    this.validateConfirmRegion();
  }

  validateConfirmRegion(): void {
    const confirmRegionField: AbstractControl = this.form.controls.confirm_region;

    if (confirmRegionField.value !== '' && this.regionSelected.val !== confirmRegionField.value) {
      confirmRegionField.addValidators([this.matchRegionValFn]);
    } else {
      confirmRegionField.removeValidators([this.matchRegionValFn]);
    }
    confirmRegionField.updateValueAndValidity();
  }

  toggleConfirmRegionSelection(): void {
    this.showConfirmRegionSelection = true;
  }

  handleApiResponse(data: ApiResponse): void {
    if (data.status === 'success') {
      this.showConfirmation = true;
      this.pageHeading = this.compStrs.congratulations_heading;
    } else {
      this.commonHelperService.showApiError();
    }

    this.loaderService.hideLoading();
  }

  generatePayload(): SetPassword {
    const regionId = parseInt(this.regionSelected.id.toString(), 10);

    const payload: SetPassword = {
      password: this.password,
      token: this.token,
      regionId
    };
    return payload;
  }

  setPasswordAndRegion(): void {
    const payload: SetPassword = this.generatePayload();

    this.setPasswordSub = this.accountManagerService.setPassword(payload)
    .subscribe({
      next: (data: ApiResponse) => this.handleApiResponse(data),
      error: () => this.commonHelperService.handleApiError()
    });
  }

  inviteFromSalesforce(payload: InviteFromSalesforce): void {
    this.inviteFromSfSub = this.accountManagerService.inviteFromSalesforce(payload)
    .subscribe({
      next: (data: ApiResponse) => this.handleApiResponse(data),
      error: () => this.commonHelperService.handleApiError()
    });
  }

  setUserFlow(): void {
    if (this.payload) {
      this.payload.regionId = this.regionSelected.id.toString();
      this.loaderService.showLoading();
      this.inviteFromSalesforce(this.payload);
    }
    else {
      this.loaderService.showLoading();
      this.setPasswordAndRegion();
    }
  }

  submit(): void {
    if (this.form.valid) {
      this.setUserFlow();
    } else {
      this.form.markAllAsTouched();
      this.commonHelperService.setFocusOnInvalidFormField(this.form, this.el);
    }
  }

  ngOnDestroy(): void {
    this.setPasswordSub?.unsubscribe();
    this.inviteFromSfSub?.unsubscribe();
  }

}
