<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KS2GSV" height="0" width="0"
    style="display:none;visibility:hidden" title="GTM"></iframe>
</noscript>

<div>
    <ng-container *ngIf="!isShowConfirmation && isShowCreateAccountForm">
        <div class="text-center">
            <h1 class="my-4 page-heading">{{ compStrs.create_acc_heading | translate }}</h1>
        </div>
        <div [formGroup]="form">
            <div class="wg-form-row">
                <div class="wg-form-col">
                    <div class="form-group required">
                        <label>{{ compStrs.first_name_lbl | translate }}</label>
                        <input 
                            type="text" 
                            class="form-control"
                            formControlName="firstName"
                            wgTrim>
                        <wg-form-control-error [control]="form.controls['firstName']"></wg-form-control-error>
                    </div>
                    <div class="form-group required">
                        <label>{{ compStrs.last_name_lbl | translate }}</label>
                        <input 
                            type="text" 
                            class="form-control"
                            formControlName="lastName"
                            wgTrim>
                        <wg-form-control-error [control]="form.controls['lastName']"></wg-form-control-error>
                    </div>
                    <div class="form-group required">
                        <label>{{ compStrs.username_lbl | translate }}</label>
                        <input 
                            type="text" 
                            class="form-control"
                            formControlName="userName"
                            (keyup)="updateUsernameFieldValidity()"
                            wgTrim>
                        <wg-form-control-error [control]="form.controls['userName']"></wg-form-control-error>
                    </div>
                    <div class="form-group required">
                        <label>{{ compStrs.email_lbl | translate }}</label>
                        <input 
                            type="text" 
                            class="form-control"
                            formControlName="email"
                            wgTrim>
                        <wg-form-control-error [control]="form.controls['email']"></wg-form-control-error>
                    </div>
                    <div class="form-group required">
                        <label>{{ compStrs.phone_lbl | translate }}</label>
                        <input 
                            type="text" 
                            class="form-control"
                            formControlName="phone"
                            wgTrim>
                        <wg-form-control-error [control]="form.controls['phone']"></wg-form-control-error>
                    </div>
                    <div class="form-group required">
                        <label>{{ compStrs.company_name_lbl | translate }}</label>
                        <input 
                            type="text" 
                            class="form-control"
                            formControlName="companyName"
                            wgTrim>
                        <wg-form-control-error [control]="form.controls['companyName']"></wg-form-control-error>
                    </div>
                    <div class="form-group required">
                        <label>{{ compStrs.industry_lbl | translate }}</label>
                        <wg-dropdown 
                            id="industry"
                            [dropdownList]="industryList"
                            placeHolderText="Select from the list"
                            formControlName="industry"
                            [isInvalid]="form.controls['industry'].touched && form.controls['industry'].invalid"
                            (focusout)="form.controls['industry'].markAllAsTouched()"
                            (selectedEvent)="dropdownSelected($event, 'industry')">
                        </wg-dropdown>
                        <wg-form-control-error [control]="form.controls['industry']"></wg-form-control-error>
                    </div>
                    <div class="form-group required">
                        <label>{{ compStrs.street_address_lbl | translate }}</label>
                        <input 
                            type="text" 
                            class="form-control"
                            formControlName="street"
                            wgTrim>
                        <wg-form-control-error [control]="form.controls['street']"></wg-form-control-error>
                    </div>
                    <div class="form-group required">
                        <label>{{ compStrs.postal_code_lbl | translate }}</label>
                        <input 
                            type="text" 
                            class="form-control"
                            formControlName="postalCode"
                            wgTrim>
                        <wg-form-control-error [control]="form.controls['postalCode']"></wg-form-control-error>
                    </div>
                    <div class="form-group required">
                        <label>{{ compStrs.city_lbl | translate }}</label>
                        <input 
                            type="text" 
                            class="form-control"
                            formControlName="city"
                            wgTrim>
                        <wg-form-control-error [control]="form.controls['city']"></wg-form-control-error>
                    </div>
                    <div class="form-group required" *ngIf="stateList.length">
                        <label>{{ compStrs.state_province_region_lbl | translate }}</label>
                        <wg-dropdown 
                            id="state"
                            [dropdownList]="stateList"
                            placeHolderText="Select from the list"
                            formControlName="state"
                            [isInvalid]="form.controls['state'].touched && form.controls['state'].invalid"
                            (focusout)="form.controls['state'].markAllAsTouched()"
                            [userSelection]="stateSelected"
                            (selectedEvent)="dropdownSelected($event, 'state')">
                        </wg-dropdown>
                        <wg-form-control-error [control]="form.controls['state']"></wg-form-control-error>
                    </div>
                    <div class="form-group required">
                        <label>{{ compStrs.country_lbl | translate }}</label>
                        <wg-dropdown 
                            id="country"
                            [dropdownList]="countryList"
                            placeHolderText="Select from the list"
                            formControlName="country"
                            [isInvalid]="form.controls['country'].touched && form.controls['country'].invalid"
                            (focusout)="form.controls['country'].markAllAsTouched()"
                            (selectedEvent)="dropdownSelected($event, 'country')">
                        </wg-dropdown>
                        <wg-form-control-error [control]="form.controls['country']"></wg-form-control-error>
                    </div>
                    <div class="form-group for-checkbox-inline" *ngIf="isShowOptInEmail">            
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="checkbox" name="test-radio" class="form-check-input" formControlName="optedInForEmail">
                                <span [innerHTML]="compStrs.receive_marketing_email_text | translate"></span> 
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-actions mb">
                <wg-button 
                    color="primary"
                    (click)="submit()">
                    {{ compStrs.create_acc_btn_lbl | translate }}
                </wg-button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isShowConfirmation">
        <div class="text-center mt-4">
            <h4 >{{ compStrs.create_acc_check_email_msg | translate }}</h4>
            <h4>
                <span [innerHTML]="compStrs.create_acc_contact_msg | translate"></span>
            </h4>
        </div>
    </ng-container>

</div>

<wg-dialog class="wg-dialog-sm"
    [showDialog]="isShowWarning"
    [hideClickOutside]="true"
    (dialogClosedEvent)="closeDuplicateEmailWarningDialog()">
    <div dialog-title>{{ compStrs.duplicate_email_dialog_title | translate }}</div> 
    <div dialog-content>
        <p [innerHTML]="errorStrs.user_exists_email_info_msg | translate : emailTransParams"></p>
        <p>{{ errorStrs.email_duplicate | translate }}</p>
    </div>
    <div dialog-footer>
        <div class="ui-dialog-buttonpane">
            <button type="button" class="btn btn-secondary" (click)="closeDuplicateEmailWarningDialog()">
                {{ compStrs.duplicate_email_dialog_btn_no_lbl | translate }}
            </button>
            <button type="button" class="btn btn-primary" (click)="createAccountWithDuplicateEmail()">
                {{ compStrs.duplicate_email_dialog_proceed_btn_lbl | translate }}
            </button>
        </div>
    </div>
</wg-dialog>

<ng-container *ngIf="isInviteUserinNewAccountForm">
    <wgc-invite-user-in-new-account [accountInfo]="accountInfo" [contactId]="contactId"></wgc-invite-user-in-new-account>
</ng-container>

<ng-container *ngIf="isInviteUserForm && !isInviteUserinNewAccountForm && !isShowCreateAccountForm">
    <wgc-invite-user [accountInfo]="accountInfo" [contactId]="contactId"></wgc-invite-user>
</ng-container>

