import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MsalGuard } from '@azure/msal-angular';

import { CreateAccountComponent } from './create-account/create-account.component';
import { ErrorComponent } from './error/error.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RetrieveUserNameComponent } from './retrieve-user-name/retrieve-user-name.component';
import { SelectRegionComponent } from './select-region/select-region.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { EmailComponent } from './email/email.component';

const routes: Routes = [
  {
    path: 'create-account',
    component: CreateAccountComponent
  },
  {
    path: 'set-password',
    component: SetPasswordComponent
  },
  {
    path: 'select-region',
    component: SelectRegionComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'retrieve-user-name',
    component: RetrieveUserNameComponent
  },
  {
    path: 'change-email',
    component: EmailComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: '',
    redirectTo: 'create-account',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'create-account'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
