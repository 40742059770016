import { Injectable } from '@angular/core';

import * as bcrypt from 'bcryptjs';


@Injectable({
  providedIn: 'root'
})
export class PayloadHashingService {

    // The payload may vary depending upon the calls from UI
    hashPayload(payload: any): string {
      const salt = bcrypt.genSaltSync(10);
      const hashedPayload = bcrypt.hashSync(payload, salt);
      const hashedToken = ''.concat(hashedPayload, '#', salt);
      return hashedToken;
    }
}
