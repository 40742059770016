<ng-container>
    <div class="text-center">
        <h1 class="my-4 page-heading">{{ errorContent.errorHeader | translate }}</h1>
    </div>
    <div class="text-center">
        <h4>
            <span [innerHTML]="errorContent.errorMessage | translate"></span>
        </h4>
        <h4>
            <span [innerHTML]="errorContent.redirectMessage | translate"></span>
        </h4>
    </div>
</ng-container>

