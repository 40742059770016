<header class="header">  
  <div class="section-title mx-3">
    <div class="d-flex justify-content-between">
      <img src="images/wgc/svg-icons/wgc-light-logo.svg" alt="wgc-logo">
      <a href="{{ supportUrl }}">
        <wg-icon name="question-circle" class="size-18 color-slate"></wg-icon>
      </a>
    </div>
  </div>
</header>
