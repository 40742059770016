import { Component, OnInit } from '@angular/core';

import { CommonHelperService } from '../shared/common-helper.service';
import { ErrorContent } from '../shared/model';


@Component({
  selector: 'wgc-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit {

  errorContent: ErrorContent;

  ngOnInit(): void {
    this.errorContent = this.commonHelperService.errorContent;
  }

  constructor(private commonHelperService: CommonHelperService) {
  }

}

