<div>
    <ng-container *ngIf="!showConfirmation">
        <div class="text-center">
            <h1 class="mt-4 page-heading" [innerHTML]="compStrs.select_region_heading | translate"></h1>
        </div>
        <div [formGroup]="form">
            <div class="text-center">
                <p>{{ compStrs.select_region_sub_heading | translate }}</p>
                <h5>{{ compStrs.select_region_sub_heading_2 | translate }}</h5>
                <span [innerHTML]="compStrs.select_region_learn_more_text | translate"></span>
            </div>
            <div class="wg-form-row mt-3">
                <div class="wg-form-col">
                    <div class="form-group required">
                        <label>{{ compStrs.select_region_dropdown_lbl | translate }}</label>
                        <wg-dropdown 
                            formControlName="region"
                            [isInvalid]="form.controls['region'].touched && form.controls['region'].invalid"
                            (focusout)="form.controls['region'].markAllAsTouched()"
                            [dropdownList]="regionsList"
                            [placeHolderText]="compStrs.select_region_dropdown_placeholder_lbl | translate"
                            (selectedEvent)="regionDropDownSelected($event)">
                        </wg-dropdown>
                        <wg-form-control-error [control]="form.controls['region']"></wg-form-control-error>
                    </div>
                </div>
            </div>
            <div class="form-actions mb" *ngIf="!showConfirmRegionSelection">
                <wg-button 
                    color="primary"
                    (click)="toggleConfirmRegionSelection()">
                    {{ compStrs.save_region_btn_lbl | translate }}
                </wg-button>
            </div>
            <div *ngIf="showConfirmRegionSelection">
                <div class="text-center" *ngIf="showConfirmRegionSelection">
                    <h4>
                        <span [innerHTML]="compStrs.confirm_region_heading | translate : confirmLblTransParams"></span>
                    </h4>
                    <div class="d-flex justify-content-around">
                        <div class="col-11">
                            <h5>{{ compStrs.confirm_region_heading_2 | translate }}</h5>
                        </div>
                    </div>
                </div>
                <div class="form-group required">
                    <label>{{ compStrs.confirm_select_region_lbl | translate }}</label>
                    <input 
                        type="text" 
                        class="form-control"
                        (input)="validateConfirmRegion()"
                        formControlName="confirm_region">
                    <wg-form-control-error [control]="form.controls['confirm_region']"></wg-form-control-error>
                </div>
                <div class="form-actions">
                    <wg-button 
                        color="primary"
                        (click)="submit()">
                        {{ compStrs.set_region_btn_lbl | translate }}
                    </wg-button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="showConfirmation">
        <div class="text-center">
            <h1 class="mt-4 page-heading">{{ compStrs.congratulations_heading | translate }}</h1>
        </div>
        <div class="text-center mt-4">
            <h4>{{ compStrs.acc_created_msg | translate }}</h4>
        </div>
        <div class="text-center mt-4">
            <wg-button
              color="primary">
              <a class="btn-a" href="{{ signInUrl }}">{{ compStrs.return_to_login_page_btn_lbl | translate }}</a> 
            </wg-button>
          </div>
    </ng-container>
</div>
