import { ElementRef, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { LoaderService } from '@watchguard/wg-loader';
import { NotificationType, WgNotificationsService } from '@watchguard/wg-notifications';
import { getEnv } from '@watchguard/wg-utils';

import { Config, EnvConfig, EnvUrls, ErrorContent } from './model';
import { APP_TITLE_STRINGS, ERROR_COMP_STRINGS, ERROR_STRINGS } from './ui-strings';


export const SIGN_IN_URL: EnvUrls = {
  dev: 'https://login.devci.watchguard.com/',
  qa: 'https://login.test.watchguard.com/',
  staging: 'https://login.staging.watchguard.com/',
  prod: 'https://login.watchguard.com/'
};

export const SIGN_IN_TO_CLOUD_URL: EnvUrls = {
  dev: 'https://dev.cloud.watchguard.com/',
  qa: 'https://qa.cloud.watchguard.com/',
  staging: 'https://staging.cloud.watchguard.com/',
  prod: 'https://cloud.watchguard.com/'
};

export const GUARD_API_URL: EnvUrls = {
  dev: 'https://guardapi.wgid.dev.usa.cloud.watchguard.com/',
  qa: 'https://guardapi.wgid.qa.usa.cloud.watchguard.com/',
  staging: 'https://guardapi.wgid.staging.deu.cloud.watchguard.com/',
  prod: 'https://guardapi.wgid.usa.cloud.watchguard.com/'
};

export const AUTH_CONFIG: EnvConfig = {
    dev: {
        auth: {
            clientId: 'b7efc23c-33c4-4fa6-9e8f-315d58b93a4b',
            authority: 'https://wglogindevci.b2clogin.com/wglogindevci.onmicrosoft.com/B2C_1A_Local_Signin',
            knownAuthorities: ['wglogindevci.b2clogin.com'],
            postLogoutUrl:'https://dev.cloud.watchguard.com/'
        },
        accountManagerBaseUrl: 'https://accountmanager.dev.cloud.watchguard.com',
        scope: 'https://wglogindevci.onmicrosoft.com/b7efc23c-33c4-4fa6-9e8f-315d58b93a4b/user.read'
    },
    qa: {
        auth: {
            clientId: '12dc7bda-8bff-4381-b80c-1f7d0e87ff00',
            authority: 'https://wglogintest.b2clogin.com/wglogintest.onmicrosoft.com/B2C_1A_Local_Signin',
            knownAuthorities: ['wglogintest.b2clogin.com'],
            postLogoutUrl:'https://qa.cloud.watchguard.com/'
        },
        accountManagerBaseUrl: 'https://accountmanager.qa.cloud.watchguard.com',
        scope: 'https://wglogintest.onmicrosoft.com/12dc7bda-8bff-4381-b80c-1f7d0e87ff00/user.read',
       
    },
    staging: {
        auth: {
            clientId: '8f869df4-36e0-44da-92bb-042c30471b79',
            authority: 'https://wgloginstaging.b2clogin.com/wgloginstaging.onmicrosoft.com/B2C_1A_Local_Signin',
            knownAuthorities: ['wgloginstaging.b2clogin.com'],
            postLogoutUrl:'https://staging.cloud.watchguard.com/'
        },
        accountManagerBaseUrl: 'https://accountmanager.staging.cloud.watchguard.com',
        scope: 'https://wgloginstaging.onmicrosoft.com/8f869df4-36e0-44da-92bb-042c30471b79/8f869df4-36e0-44da-92bb-042c30471b79'
    },
    prod: {
        auth: {
            clientId: '045d6b50-edc0-4344-8440-4544a9d91ea9',
            authority: 'https://wglogin.b2clogin.com/wglogin.onmicrosoft.com/B2C_1A_Local_Signin',
            knownAuthorities: ['wglogin.b2clogin.com'],
            postLogoutUrl:'https://cloud.watchguard.com/'
        },
        accountManagerBaseUrl: 'https://accountmanager.cloud.watchguard.com',
        scope: 'https://wglogin.onmicrosoft.com/045d6b50-edc0-4344-8440-4544a9d91ea9/045d6b50-edc0-4344-8440-4544a9d91ea9'
    }
};

@Injectable({
    providedIn: 'root'
})
export class CommonHelperService {

    errorStrs = ERROR_STRINGS;
    titleStrs = APP_TITLE_STRINGS;
    errorCompStrs = ERROR_COMP_STRINGS;
    errorContent: ErrorContent = {
        errorHeader: this.errorCompStrs.page_heading,
        errorMessage: this.errorCompStrs.contact_customer_care,
        redirectMessage: this.errorCompStrs.redirect_to_start_reg
    };

    config: Config;

    constructor(
        private loaderService: LoaderService,
        private notificationService: WgNotificationsService,
        private translateService: TranslateService,
        private router: Router,
        private titleService: Title) {}

    setTitle(title: string): void {
        const translatedTitle = this.translateService.instant(title);
        // we aren't translating the WatchGuard Technologies since it is the company name
        this.titleService.setTitle( translatedTitle + ' | ' + this.titleStrs.wg_technologies);
    }

    handleApiError(customMessage?: string): void {
        this.loaderService.hideLoading();
        this.redirectToErrorPage();
        this.showNotification(NotificationType.Error, customMessage);
    }

    showNotification(type: NotificationType, customMessage?: string, interpolateMsgParams?: object): void {
        const errorMessage = customMessage ? customMessage : this.errorStrs.api_request_err_message;
        const interpolateParams = interpolateMsgParams ? interpolateMsgParams : {};

        const translatedMsg = this.translateService.instant(
            errorMessage,
            interpolateParams
        );

        if (type === NotificationType.Info){
            this.notificationService.info(translatedMsg);
        } else {
            this.notificationService.error(translatedMsg);
        }
    }

    setFocusOnInvalidFormField(form: UntypedFormGroup, el: ElementRef<HTMLInputElement>): void {
        for (const key of Object.keys(form.controls)) {
            if (form.controls[key].invalid) {
                const isDropdown = (key === 'industry' || key === 'country' || key === 'state');
                const selector = isDropdown ? '#' + key + ' input' : '[formcontrolname="' + key + '"]';
                const invalidControl = this.getFormFieldElementRef(el, key, selector);
                invalidControl.focus();
                break;
           }
        }
    }

    getFormFieldElementRef(el: ElementRef<HTMLInputElement>, key: string, selector: string): HTMLInputElement {
        return el.nativeElement.querySelector(selector);
    }

    showApiError(errorContent?: ErrorContent, isRegionNotSet?: boolean): void {
        this.redirectToErrorPage(errorContent);
        if (!isRegionNotSet) {
            this.showNotification(
                NotificationType.Error,
                this.errorStrs.api_request_err_message
            );
        }
    }

    redirectToErrorPage(errorContent?: ErrorContent): void {
        this.errorContent = errorContent ? errorContent : this.errorContent;
        this.router.navigate(['error']);
    }

    convertQueryParamsToLowercase(queryParam: any): string {
        return String(queryParam).toLowerCase();
    }

    getUrlByEnv(urlType: string): string {
        const envUrls = urlType === 'signIn' ? SIGN_IN_URL : urlType === 'signInToCloud' ? SIGN_IN_TO_CLOUD_URL : GUARD_API_URL;
        const env = getEnv();

        if (env === 'dev') {
           return envUrls.dev;
        } else if (env === 'qa') {
           return envUrls.qa;
        } else if (env === 'staging') {
           return envUrls.staging;
        }

        return envUrls.prod;
    }
    
    loadConfig(): void {
        this.config = this.getConfigByEnv();
    }

    getConfigByEnv(): Config {
        const envConfig: EnvConfig = AUTH_CONFIG;
        const env: string = getEnv();
        return env ? envConfig[env] : envConfig.prod;
    }
}
