<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KS2GSV" height="0" width="0"
        style="display:none;visibility:hidden" title="GTM"></iframe>
</noscript>

<div>
    <div class="text-center">
      <h1 class="my-4 page-heading">{{ compStrs.page_heading | translate }}</h1>
    </div>

    <ng-container *ngIf="!requestSubmitted" [formGroup]="form">
        <div class="text-center">
            <h4>{{ compStrs.enter_user_name_text | translate }}</h4>
        </div>
        <div class="form-group required">
            <label>{{ compStrs.username_lbl | translate }}</label>
            <input 
                type="text" 
                class="form-control"
                formControlName="userName"
                wgTrim>
            <wg-form-control-error [control]="form.controls['userName']"></wg-form-control-error>
        </div>
        <div class="form-actions mb">
            <wg-button 
                color="primary"
                (click)="submit()">
                {{ compStrs.submit_req_btn | translate }}
            </wg-button>
        </div>
    </ng-container>
    <ng-container *ngIf="requestSubmitted">
        <div class="text-center">
            <h4>{{ compStrs.email_sent_msg | translate }}</h4>
            <h4>
                <span [innerHTML]="compStrs.email_sent_msg_2 | translate"></span>
            </h4>
        </div>
    </ng-container>
</div>
