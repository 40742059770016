<wgc-header-main></wgc-header-main>

<div class="text-center mt-4">
    <img src="images/wgc/svg-icons/wgc-dark-logo.svg" alt="wgc-logo">
</div>

<div class="container-fluid col-sm-6 col-md-6 d-flex justify-content-around">
    <div class="col-sm-6 col-md-5">
        <router-outlet></router-outlet>
    </div>
</div>

<wg-notifications></wg-notifications>

<wg-loader
  [useService]="true"
  [modal]="true">
</wg-loader>
