<div>
    <ng-container *ngIf="!isShowConfirmation">
        <div class="text-center">
            <h1 class="my-4 page-heading">{{ compStrs.invite_from_sf_heading | translate }}</h1>
        </div>
        <div [formGroup]="form">
            <div class="wg-form-row">
                <div class="wg-form-col">
                    <div class="form-group required">
                        <label>{{ addAccCompStrs.first_name_lbl | translate }}</label>
                        <input 
                            type="text" 
                            class="form-control"
                            formControlName="firstName"
                            wgTrim>
                        <wg-form-control-error [control]="form.controls['firstName']"></wg-form-control-error>
                    </div>
                    <div class="form-group required">
                        <label>{{ addAccCompStrs.last_name_lbl | translate }}</label>
                        <input 
                            type="text" 
                            class="form-control"
                            formControlName="lastName"
                            wgTrim>
                        <wg-form-control-error [control]="form.controls['lastName']"></wg-form-control-error>
                    </div>
                    <div class="form-group required">
                        <label>{{ addAccCompStrs.username_lbl | translate }}</label>
                        <input 
                            type="text" 
                            class="form-control"
                            formControlName="userName"
                            (keyup)="updateUsernameFieldValidity()"
                            wgTrim>
                        <wg-form-control-error [control]="form.controls['userName']"></wg-form-control-error>
                    </div>
                    <div class="form-group required">
                        <label>{{ addAccCompStrs.phone_lbl | translate }}</label>
                        <input 
                            type="text" 
                            class="form-control"
                            formControlName="phone"
                            wgTrim>
                        <wg-form-control-error [control]="form.controls['phone']"></wg-form-control-error>
                    </div>
                    <div class="form-group required">
                        <label for="input-password">
                          {{ addAccCompStrs.password_lbl | translate }}
                        </label>
                        <wg-hide-show-password>
                          <input id="input-password" type="password" class="form-control" (input)="validateSamePassword()"
                            formControlName="password">
                        </wg-hide-show-password>
                        <wg-form-control-error [control]="form.controls['password']"></wg-form-control-error>
                    </div>
                    <div class="form-group required">
                        <label for="input-confirm-password">
                            {{ addAccCompStrs.confirm_password_lbl | translate }}
                        </label>
                        <wg-hide-show-password>
                            <input id="input-confirm-password" type="password" class="form-control" (input)="validateSamePassword()"
                            formControlName="confirm_password">
                        </wg-hide-show-password>
                        <wg-form-control-error [control]="form.controls['confirm_password']"></wg-form-control-error>
                    </div>
                </div>
            </div>
            <div class="form-actions mb">
                <wg-button 
                    color="primary"
                    (click)="submit()">
                    SAVE
                </wg-button>
            </div>
        </div>
    </ng-container>

</div>

<ng-container *ngIf="isShowConfirmation">
    <div class="text-center">
        <h1 class="mt-4 page-heading">{{ addAccCompStrs.congratulations_heading | translate }}</h1>
    </div>
    <div class="text-center mt-4">
        <h4>{{ addAccCompStrs.acc_created_msg | translate }}</h4>
    </div>
    <div class="text-center mt-4">
        <wg-button
          color="primary">
          <a class="btn-a" href="{{ signInUrl }}">{{ addAccCompStrs.return_to_login_page_btn_lbl | translate }}</a> 
        </wg-button>
    </div>
</ng-container>

