import { ElementRef, Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

import { forkJoin, Observable } from 'rxjs';

import { LoaderService } from '@watchguard/wg-loader';

import { AccountManagerService } from '../account-manager.service';
import { CommonHelperService } from '../shared/common-helper.service';
import { CustomValidatorService } from '../shared/custom-validator.service';
import { UserExistsApiResponse, ValidatePasswordResponse } from '../shared/model';


@Injectable({
    providedIn: 'root'
})
export class InviteFromSalesforceHelperService {

    isPasswordValid: boolean = false;
    isUsernameValid: boolean = false;

    passwordCompromisedValFn: ValidatorFn = this.customValidator.commonValFn('password_compromised');
    matchPasswordValFn: ValidatorFn = this.customValidator.commonValFn('password_mismatch');
    userNameExistsValFn: ValidatorFn = this.customValidator.commonValFn('userNameUnique');

    constructor(
      private customValidator: CustomValidatorService,
      private loaderService: LoaderService,
      private accountManagerService: AccountManagerService,
      private commonHelperService: CommonHelperService) {
    }

    validateSamePassword(form: UntypedFormGroup): void {
      const password: string = form.value.password || '';
      const confirmPasswordField: AbstractControl = form.controls.confirm_password;
      const passwordField: AbstractControl = form.controls.password;

      if (passwordField.hasValidator(this.passwordCompromisedValFn)) {
        passwordField.removeValidators([this.passwordCompromisedValFn]);
        passwordField.updateValueAndValidity();
      }

      if (password === '' || confirmPasswordField.value === '') {
        confirmPasswordField.removeValidators([this.matchPasswordValFn]);
      } else if (password && confirmPasswordField.value && password !== confirmPasswordField.value) {
        confirmPasswordField.addValidators([this.matchPasswordValFn]);
      } else {
        confirmPasswordField.removeValidators([this.matchPasswordValFn]);
      }
      confirmPasswordField.updateValueAndValidity();
    }

    validatePasswordAndUsername(form: UntypedFormGroup): Observable<[ValidatePasswordResponse, UserExistsApiResponse]> {
      this.loaderService.showLoading();

      const password = form.value.password || '';
      const username = form.value.userName || '';

      return forkJoin([this.accountManagerService.validatePassword(password), this.accountManagerService.checkUserNameExists(username)]);
    }

    handlePasswordValidation(isPasswordCompromised: ValidatePasswordResponse, form: UntypedFormGroup, el: ElementRef<HTMLInputElement>): boolean {
      const passwordField: AbstractControl = form.controls.password;
      if (isPasswordCompromised.status === 'success') {
        if (isPasswordCompromised.password_compromised) {
          passwordField.addValidators([this.passwordCompromisedValFn]);
          this.commonHelperService.getFormFieldElementRef(el, 'password', '[formcontrolname="password"]').focus();
          this.isPasswordValid = false;
        } else {
          passwordField.removeValidators([this.passwordCompromisedValFn]);
          this.isPasswordValid = true;
        }
        passwordField.updateValueAndValidity();
      } else {
        this.commonHelperService.showApiError();
      }
      return this.isPasswordValid;
    }

    handleUsernameValidation(duplicateUsernameExists: UserExistsApiResponse, form: UntypedFormGroup, el: ElementRef<HTMLInputElement>): boolean {
      const userNameField: AbstractControl = form.controls.userName;
      if (duplicateUsernameExists.user_exists) {
        userNameField.addValidators([this.userNameExistsValFn]);
        this.commonHelperService.getFormFieldElementRef(el, 'userName', '[formcontrolname="userName"]').focus();
        this.isUsernameValid = false;
      } else {
        userNameField.removeValidators([this.userNameExistsValFn]);
        this.isUsernameValid = true;
      }
      userNameField.updateValueAndValidity();
      return this.isUsernameValid;
    }
}

