<div>
  <ng-container *ngIf="isShowSetPasswordForm">
    <div class="text-center">
      <h2 class="my-4 page-heading">{{ addAccStrs.enter_new_password_heading | translate }}</h2>
    </div>
    <div [formGroup]="form">
      <div class="form-group required">
        <label for="input-password">
          {{ addAccStrs.password_lbl | translate }}
        </label>

        <wg-hide-show-password>
          <input id="input-password" type="password" class="form-control" (input)="validateSamePassword()"
            formControlName="password">
        </wg-hide-show-password>
        <wg-form-control-error [control]="form.controls['password']"></wg-form-control-error>
      </div>

      <div class="form-group required">
        <label for="input-confirm-password">
          {{ addAccStrs.confirm_password_lbl | translate }}
        </label>
        <wg-hide-show-password>
          <input id="input-confirm-password" type="password" class="form-control" (input)="validateSamePassword()"
            formControlName="confirm_password">
        </wg-hide-show-password>
        <wg-form-control-error [control]="form.controls['confirm_password']"></wg-form-control-error>
      </div>

      <div class="form-group for-checkbox-inline" *ngIf="isShowOptedInFromEmail && !isNewUser">            
        <div class="form-check">
          <label class="form-check-label">
            <input type="checkbox" name="test-radio" class="form-check-input" formControlName="optedInFromEmail">
            <span [innerHTML]="addAccStrs.receive_marketing_email_text | translate"></span> 
          </label>
        </div>
      </div>

      <div class="text-center mt-4" *ngIf="isShowOptedInFromEmail && !isNewUser">   
        <span [innerHTML]="resetPwdStrs.reset_password_privacy_policy_text | translate"></span> 
      </div>

      <div class="form-actions mb">
        <wg-button 
          color="primary"
          (click)="submit()">
          {{ addAccStrs.set_password_btn_lbl | translate }}
        </wg-button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isShowConfirmation">
    <div class="text-center">
      <h1 class="mt-4 page-heading">{{ addAccStrs.congratulations_heading | translate }}</h1>
    </div>
    <div class="text-center mt-4">
      <h4>{{ resetPwdStrs.reset_password_successful | translate }}</h4>
    </div>
    <div class="text-center mt-4">
      <wg-button
        color="primary">
        <a class="btn-a" href="{{ signInUrl }}">{{ addAccStrs.return_to_login_page_btn_lbl | translate }}</a> 
      </wg-button>
    </div>
  </ng-container>

  <ng-container *ngIf="isShowSelectRegion">
    <wgc-select-region [password]="password" [token]="token"></wgc-select-region>
  </ng-container>
</div>

