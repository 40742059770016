/**
 * Service that mocks the HTTP responses (get) and loads
 * our data instead of actually hitting a backend
 *
 * Useful when backend is down OR we just want to test things
 * out in offline mode
 *
 */

import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpXsrfTokenExtractor
} from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { mockUrls } from '../mock/mock_urls';


const FEATURE_INFO = 'feature_info';

@Injectable()
export class HttpMockRequestInterceptor implements HttpInterceptor {
  constructor(private injector: Injector,
    private xsrf: HttpXsrfTokenExtractor) {
    console.log('---------- RUNNING APP WITH MOCK DATA -----------');
    console.log('Following APIs have been hooked up with mock data:');
    console.table(mockUrls);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    for (const element of mockUrls) {
      if (new RegExp(element.url).test(request.urlWithParams)) {
        console.log('loaded from mock json: ' + request.urlWithParams);
        return of(new HttpResponse({ status: 200, body: ((element.json) as any).default }));
      }
    }
    return next.handle(request);
  }


  setHeaders(req: HttpRequest<any>): HttpRequest<any> {
    const featureInfo = req.headers.get(FEATURE_INFO);
    const apiContextId: {[key: string]: any} = {};

    if (req.method.toLowerCase() !== 'get' && req.method.toLowerCase() !== 'head') {
      req = req.clone({ headers: req.headers.set('X-XSRF-Token', this.xsrf.getToken() || '') });
    }

    //TODO - Remove me when AQE no longer requires api key
    if (req.url.indexOf('abstractquery') !== -1) {
      req = req.clone({ headers: req.headers.set('x-api-key', 'xsEvJQ0yOKo9IKBEWt3FPLQ0xe8lNfGVT+eZN+Hm61M=') });
    }

    if (featureInfo) {
      req = req.clone({ headers: req.headers.delete(FEATURE_INFO, featureInfo) });
      const obj = JSON.parse(featureInfo);
      apiContextId[FEATURE_INFO] = obj;
    }

    apiContextId.context_id = 'mock-id';

    req = req.clone({ headers: req.headers.set('WGC-API-CONTEXT-ID',
      encodeURIComponent(JSON.stringify(apiContextId))) });

    req = req.clone({
      withCredentials: true
    });
    return req;
  }
}
