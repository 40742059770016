import { OnInit , OnDestroy, Component, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';

import { LoaderService } from '@watchguard/wg-loader';

import { AccountManagerService } from '../account-manager.service';
import { CommonHelperService } from '../shared/common-helper.service';
import { CustomValidatorService } from '../shared/custom-validator.service';
import { UserExistsApiResponse } from '../shared/model';
import { APP_TITLE_STRINGS, RETRIEVE_USER_NAME_STRINGS } from '../shared/ui-strings';


@Component({
  selector: 'wgc-retrieve-user-name',
  templateUrl: './retrieve-user-name.component.html'
})
export class RetrieveUserNameComponent implements OnInit, OnDestroy{

  requestSubmitted = false;
  form: UntypedFormGroup;

  compStrs = RETRIEVE_USER_NAME_STRINGS;
  titleStrs = APP_TITLE_STRINGS;

  retrieveUserNameSub: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private customValidator: CustomValidatorService,
    private accountManagerService: AccountManagerService,
    private commonHelperService: CommonHelperService,
    private loaderService: LoaderService,
    private el: ElementRef) {
  }

  ngOnInit(): void {
    this.commonHelperService.setTitle(this.titleStrs.forgot_user_name);
    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group({
      email: [
        '',
        [
          this.customValidator.requiredFn('email'),
          this.customValidator.regexFn('email'),
          this.customValidator.lengthValFn('email', 80, 4)
        ]
      ]
    });
  }

  handleApiResponse(data: UserExistsApiResponse): void {
    if (data.status === 'success') {
      this.requestSubmitted = true;
    } else {
      this.commonHelperService.showApiError();
    }

    this.loaderService.hideLoading();
  }

  sendRetrieveUserNameEmail(): void {
    const email = this.form.value.email;

    this.retrieveUserNameSub = this.accountManagerService.retrieveUserName({ email })
    .subscribe({
      next: (data: UserExistsApiResponse) => this.handleApiResponse(data),
      error: () => this.commonHelperService.handleApiError()
    });

  }

  submit(): void {
    if (this.form.valid) {
      this.loaderService.showLoading();
      this.sendRetrieveUserNameEmail();
    } else {
      this.form.markAllAsTouched();
      this.commonHelperService.setFocusOnInvalidFormField(this.form, this.el);
    }
  }

  ngOnDestroy(): void {
    this.retrieveUserNameSub?.unsubscribe();
  }

}
