import { Component } from '@angular/core';


@Component({
  selector: 'wgc-app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'wgid-account-manager-ui';
}
