import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  ResetPassword,
  RequestResetPassword,
  RetrieveUserName,
  SetPassword,
  UserExistsApiResponse,
  CreateAccountData,
  ValidateTokenResponse,
  ValidatePasswordResponse,
  GetAccountInfoByContactIdResponse,
  InviteFromSalesforce,
  ValidateEmailTokenResponse,
  ApiResponse, 
  ConfirmPendingEmail,
  CancelPendingEmail,
  GetPendingEmailResponse } from './shared/model';
import { CommonHelperService } from './shared/common-helper.service';

@Injectable({
  providedIn: 'root'
})
export class AccountManagerService {

  private accountManagerBaseURL = 'acctmgr';
  private guardApiURL = '';

  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
      private http: HttpClient,
      private commonHelperService: CommonHelperService) {
      this.guardApiURL = this.commonHelperService.getUrlByEnv('guardApiURL');
  }

  checkUserNameExists(userName: string): Observable<UserExistsApiResponse> {

    const url = this.guardApiURL + `${this.accountManagerBaseURL}/user/user_name`;
    const params = new HttpParams()
      .set('user_name', userName);
    return this.http.get<UserExistsApiResponse>(url, { params, headers: this.headers }).pipe(catchError(this.handleError));
  }

  checkDuplicateEmail(email: string): Observable<UserExistsApiResponse> {
    const url = this.guardApiURL + `${this.accountManagerBaseURL}/user/email`;
    const params = new HttpParams()
      .set('email', email);
    return this.http.get<UserExistsApiResponse>(url, { params, headers: this.headers }).pipe(catchError(this.handleError));
  }

  createAccount(payload: CreateAccountData): Observable<any> {
    const url = this.guardApiURL + `${this.accountManagerBaseURL}/account`;
    return this.http.post(url, payload, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  sendResetPassword(payload: RequestResetPassword): Observable<any> {
    const url = this.guardApiURL + `${this.accountManagerBaseURL}/forgot/password`;
    return this.http.post(url, payload, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  setPassword(payload: SetPassword): Observable<any> {
    const url = this.guardApiURL + `${this.accountManagerBaseURL}/credential/password`;
    return this.http.post(url, payload, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  resetPassword(payload: ResetPassword): Observable<any> {
    const url = this.guardApiURL + `${this.accountManagerBaseURL}/credential/password`;
    return this.http.put(url, payload, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  retrieveUserName(payload: RetrieveUserName): Observable<any> {
    const url = this.guardApiURL + `${this.accountManagerBaseURL}/forgot/user_name`;
    return this.http.post(url, payload, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  validateToken(token: string, new_user: boolean): Observable<any> {
    const url = this.guardApiURL +  `${this.accountManagerBaseURL}/user/token/validate`;
    const params = new HttpParams()
    .set('token', token)
    .set('new_user', new_user);
    return this.http.get<ValidateTokenResponse>(url, { params, headers: this.headers }).pipe(catchError(this.handleError));
  }

  validatePassword(password: string): Observable<ValidatePasswordResponse> {
    const url = this.guardApiURL + `${this.accountManagerBaseURL}/credential/validate_password`;
    const params = new HttpParams()
    .set('password', password);
    return this.http.get<ValidatePasswordResponse>(url, { params, headers: this.headers }).pipe(catchError(this.handleError));
  }

  getAccountInfoByContactId(contact_id: string): Observable<GetAccountInfoByContactIdResponse> {
    const url = this.guardApiURL + `${this.accountManagerBaseURL}/account/get_by_contactId`;
    const params = new HttpParams()
    .set('contact_id', contact_id);
    return this.http.get<GetAccountInfoByContactIdResponse>(url, { params, headers: this.headers }).pipe(catchError(this.handleError));
  }

  inviteFromSalesforce(payload: InviteFromSalesforce): Observable<ApiResponse> {
    const url = this.guardApiURL + `${this.accountManagerBaseURL}/inviteFromSalesForce`;
    return this.http.post<ApiResponse>(url, payload, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  validateEmailToken(token: string, federation_id: string): Observable<ValidateEmailTokenResponse> {
    const url = this.guardApiURL + `${this.accountManagerBaseURL}/user/email/validatetoken`;
    const params = new HttpParams()
    .set('email_change_token', token)
    .set('federation_id', federation_id);
    return this.http.get<ValidateEmailTokenResponse>(url, { params, headers: this.headers }).pipe(catchError(this.handleError))
  }

  confirmPendingEmail(payload: ConfirmPendingEmail): Observable<ApiResponse> {    
    const url = this.guardApiURL + `${this.accountManagerBaseURL}/user/confirmPendingEmail`;
    return this.http.patch<ApiResponse>(url, payload, {headers: this.headers }).pipe(catchError(this.handleError))
  }

  cancelPendingEmail(payload: CancelPendingEmail): Observable<ApiResponse> {
    const url = this.guardApiURL + `${this.accountManagerBaseURL}/user/cancelPendingEmail`;
    return this.http.patch<ApiResponse>(url, payload, {headers: this.headers }).pipe(catchError(this.handleError))
  }

  getPendingEmail(federationId:string): Observable<GetPendingEmailResponse> {
    const url = this.guardApiURL + `${this.accountManagerBaseURL}/user/pendingEmail`;
    const params = new HttpParams()
      .set('federationId', federationId)     
    return this.http.get<GetPendingEmailResponse>(url, {params, headers: this.headers }).pipe(catchError(this.handleError))
  }

  private handleError(error: any): Observable<never> {
    const errMsgStatus = error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    const errMsg = error.message ? error.message : errMsgStatus;
    return throwError(() => new Error(errMsg));
  }
}
