import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WgIconModule } from '@watchguard/wg-icon';

import { HeaderMainComponent } from './header-main/header-main.component';


@NgModule({
  declarations: [
    HeaderMainComponent
  ],
  imports: [
    CommonModule,
    WgIconModule
  ],
  exports: [
    HeaderMainComponent
  ]
})
export class HeaderModule { }
