import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageHelperService {

  getSession(key: string): string {
    return localStorage.getItem(key);    
  }

  setSession(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  removeSession(key: string): void {
    localStorage.removeItem(key);
  }
}
