import { Component, OnInit , OnDestroy, ElementRef, Input } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, ValidatorFn, UntypedFormGroup } from '@angular/forms';

import { map, Subscription } from 'rxjs';

import { LoaderService } from '@watchguard/wg-loader';

import {
  ApiResponse,
  ErrorContent,
  GetAccountInfoByContactIdResponse,
  InviteFromSalesforce,
  UserExistsApiResponse,
  ValidatePasswordResponse } from '../../shared/model';
import {
  ADD_NEW_ACC_STRINGS,
  APP_TITLE_STRINGS,
  ERROR_COMP_STRINGS,
  ERROR_STRINGS, INVITE_FROM_SF_COMP_STRINGS 
} from '../../shared/ui-strings';
import { CommonHelperService } from '../../shared/common-helper.service';
import { AccountManagerService } from '../../account-manager.service';
import { CustomValidatorService } from '../../shared/custom-validator.service';
import { InviteFromSalesforceHelperService } from '../invite-from-sf-helper.service';
import { REGION_IDS } from '../../shared/constants';


@Component({
  selector: 'wgc-invite-user',
  templateUrl: './invite-user.component.html'
})
export class InviteUserComponent implements OnInit, OnDestroy {

  @Input() accountInfo: GetAccountInfoByContactIdResponse;
  @Input() contactId: string;

  isShowConfirmation: boolean = false;
  isPasswordValid: boolean = false;
  isUsernameValid: boolean = false;
  signInUrl: string = '';
  form: UntypedFormGroup;
  isDuplicateUsername: UserExistsApiResponse;
  isPasswordCompromised: ValidatePasswordResponse;
  payload: InviteFromSalesforce;

  addAccCompStrs = ADD_NEW_ACC_STRINGS;
  compStrs = INVITE_FROM_SF_COMP_STRINGS;
  errorStrs = ERROR_STRINGS;
  titleStrs = APP_TITLE_STRINGS;
  errorCompStrs = ERROR_COMP_STRINGS;
  invalidRegionErrorContent: ErrorContent = {
    errorHeader: this.errorCompStrs.could_not_add_operator,
    errorMessage: this.errorCompStrs.region_not_set,
    redirectMessage: this.errorCompStrs.contact_customer_support_for_region
  };

  checkUserNameExistsSub: Subscription;
  validatePasswordSub: Subscription;
  inviteUserFromSfSub: Subscription;

  userNameExistsValFn: ValidatorFn;
  matchPasswordValFn: ValidatorFn;
  passwordCompromisedValFn: ValidatorFn;

  constructor(
    private fb: UntypedFormBuilder,
    private customValidator: CustomValidatorService,
    private accountManagerService: AccountManagerService,
    private commonHelperService: CommonHelperService,
    private loaderService: LoaderService,
    private el: ElementRef,
    private inviteFromSfHelperService: InviteFromSalesforceHelperService) {
  }

  ngOnInit(): void {
    this.commonHelperService.setTitle(this.titleStrs.invite_from_sf);
    this.initForm();
    this.signInUrl = this.commonHelperService.getUrlByEnv('signIn');
    this.initValidators();
  }

  initValidators(): void {
    this.passwordCompromisedValFn = this.inviteFromSfHelperService.passwordCompromisedValFn;
    this.matchPasswordValFn = this.inviteFromSfHelperService.matchPasswordValFn;
    this.userNameExistsValFn = this.inviteFromSfHelperService.userNameExistsValFn;
  }

  initForm(): void {
    this.form = this.fb.group({
      firstName: [
        '',
        [
          this.customValidator.requiredFn('firstName'),
          this.customValidator.regexFn('firstName'),
          this.customValidator.lengthValFn('firstName', 40)
        ]
      ],
      lastName: [
        '',
        [
          this.customValidator.requiredFn('lastName'),
          this.customValidator.regexFn('lastName'),
          this.customValidator.lengthValFn('lastName', 40)
        ]
      ],
      userName: [
        '',
        [
          this.customValidator.requiredFn('userName'),
          this.customValidator.regexFn('userName'),
          this.customValidator.lengthValFn('userName', 65, 5)
        ]
      ],
      phone: [
        '',
        [
          this.customValidator.requiredFn('phone'),
          this.customValidator.regexFn('phone'),
          this.customValidator.lengthValFn('phone', 40, 6)
        ]
      ],
      password: [
        '',
        [
          this.customValidator.requiredFn('password'),
          this.customValidator.regexFn('password')
        ]
      ],
      confirm_password: [
        '',
        [
          this.customValidator.requiredFn('confirm_password')
        ]
      ]
    });
  }

  
  isRegionIdValid(regionId: string): boolean {
    if (regionId === REGION_IDS.INVALID_REGION) {
      this.commonHelperService.showApiError(this.invalidRegionErrorContent, true);
      return false;
    }
    return true;
  }

  inviteFromSalesforce(payload: InviteFromSalesforce): void {
    const isRegionIdValid = this.isRegionIdValid(this.accountInfo.account_details.regionId);
    if (isRegionIdValid) {
      this.loaderService.showLoading();
      this.inviteUserFromSfSub = this.accountManagerService.inviteFromSalesforce(payload)
      .subscribe({
        next: (data: ApiResponse) => this.handleApiResponse(data),
        error: () => this.commonHelperService.handleApiError()
      });;
    }
  }

  handleApiResponse(data: ApiResponse): void {
    if (data.status === 'success') {
      this.isShowConfirmation = true;
    } else {
      this.commonHelperService.showApiError();
    }

    this.loaderService.hideLoading();
  }

  inviteUserFromSf(): void {
    this.payload = this.generatePayloadForInviteUser();
    this.inviteFromSalesforce(this.payload);
  }

  generatePayloadForInviteUser(): InviteFromSalesforce {
    const formData: InviteFromSalesforce = {
      ...this.form.value,
      nativeSalesforceContactId: this.contactId,
      accountId: this.accountInfo.account_details.contact.accountId__c,
      regionId: this.accountInfo.account_details.regionId,
      email: this.accountInfo.account_details.contact.email,
      accountExists: this.accountInfo.account_exists
    };
    return formData;
  }

  validateSamePassword(): void {
    this.inviteFromSfHelperService.validateSamePassword(this.form);
  }

  handleValidatePasswordAndUsernameResponse(isPasswordCompromised: ValidatePasswordResponse, duplicateUsernameExists: UserExistsApiResponse): void {
    this.isPasswordValid = this.inviteFromSfHelperService.handlePasswordValidation(isPasswordCompromised, this.form, this.el);
    this.isUsernameValid = this.inviteFromSfHelperService.handleUsernameValidation(duplicateUsernameExists, this.form, this.el);

    if(this.isPasswordValid && this.isUsernameValid) {
      this.inviteUserFromSf();
    }

    this.loaderService.hideLoading();
  }

  validatePasswordAndUsername(): void {
    this.inviteFromSfHelperService.validatePasswordAndUsername(this.form).pipe(map(([isPasswordCompromised, isDuplicateUsername]) => {
      return (this.isPasswordCompromised = isPasswordCompromised, this.isDuplicateUsername = isDuplicateUsername);
      })).subscribe({
        next: ()  => this.handleValidatePasswordAndUsernameResponse(this.isPasswordCompromised, this.isDuplicateUsername),
        error: () => this.commonHelperService.handleApiError()
      });
  }

  updateUsernameFieldValidity(): void {
    const userNameField: AbstractControl = this.form.controls.userName;
    userNameField.removeValidators([this.userNameExistsValFn]);
    userNameField.updateValueAndValidity();
  }

  submit(): void {
    if (this.form.valid) {
      this.validatePasswordAndUsername();
    } else {
      this.form.markAllAsTouched();
      this.commonHelperService.setFocusOnInvalidFormField(this.form, this.el);
    }
  }

  ngOnDestroy(): void {
    this.checkUserNameExistsSub?.unsubscribe();
    this.validatePasswordSub?.unsubscribe();
    this.inviteUserFromSfSub?.unsubscribe();
  }
}

