import * as accountCreation from './data/account_creation.json';
import * as accountCreationFail from './data/account_creation_fail.json';
import * as resetPassword from './data/reset_password.json';
import * as resetPasswordFail from './data/reset_password_fail.json';
import * as sendResetPassword from './data/reset_password_mail.json';
import * as sendResetPasswordFail from './data/reset_password_mail_fail.json';
import * as retrieveUserName from './data/retrieve_user_name.json';
import * as retrieveUserNameFail from './data/retrieve_user_name_fail.json';
import * as setPassword from './data/set_password.json';
import * as setPasswordFail from './data/set_password_fail.json';
import * as validateUniqueUserName from './data/validate_unique_user_name.json';
import * as validateNonUniqueUserName from './data/validate_non_unique_user_name.json';
import * as validateDuplicateEmail from './data/validate_duplicate_email.json';
import * as validateNonDuplicateEmail from './data/validate_non_duplicate_email.json';


export const mockUrls = [
  {
    url: '/account-manager-mw/CreateAccount',
    json: accountCreation
  },
  {
    url: '/account-manager-mw/FailCreateAccount',
    json: accountCreationFail
  },
  {
    url: '/account-manager-mw/SendResetPassword',
    json: sendResetPassword
  },
  {
    url: '/account-manager-mw/FailSendResetPassword',
    json: sendResetPasswordFail
  },
  {
    url: '/account-manager-mw/[^/]+/SetPassword',
    json: setPassword
  },
  {
    url: '/account-manager-mw/[^/]+/FailSetPassword',
    json: setPasswordFail
  },
  {
    url: '/account-manager-mw/[^/]+/ResetPassword',
    json: resetPassword
  },
  {
    url: '/account-manager-mw/[^/]+/FailResetPassword',
    json: resetPasswordFail
  },
  {
    url: '/account-manager-mw/RetrieveUserName',
    json: retrieveUserName
  },
  {
    url: '/account-manager-mw/FailRetrieveUserName',
    json: retrieveUserNameFail
  },
  {
    url: '/account-manager-mw/ValidateUniqueUserName',
    json: validateUniqueUserName
  },
  {
    url: '/account-manager-mw/ValidateNonUniqueUserName',
    json: validateNonUniqueUserName
  },
  {
    url: '/account-manager-mw/ValidateDuplicateEmail',
    json: validateDuplicateEmail
  },
  {
    url: '/account-manager-mw/ValidateNonDuplicateEmail',
    json: validateNonDuplicateEmail
  }
];
