import { Component } from '@angular/core';

@Component({
  selector: 'wgc-header-main',
  templateUrl: './header-main.component.html',
  styleUrls: ['./header-main.component.scss']
})
export class HeaderMainComponent {

  supportUrl = 'https://www.watchguard.com/help/docs/help-center/en-US/index_CSH.html#15086';

}
