<ng-container *ngIf="isShowEmail">
    <div>
        <div class="text-center">
            <h1 class="my-4 page-heading">{{ emailChangeString.email_title | translate }}</h1>
        </div>
        <div class="text-center">
            <h4>{{ emailChangeString.email_message | translate }}</h4>
        </div>
    </div>
    <div class="d-flex flex-column align-items-center mt-2">
        <strong>{{ emailChangeString.previous_email_address_lbl | translate }}:</strong>
        <span>{{ oldEmail | translate }}</span>
    </div>
    <div class="d-flex flex-column align-items-center mt-2">
        <strong>{{ emailChangeString.new_email_address_lbl | translate }}:</strong>
        <span>{{ newEmail | translate }}</span>
    </div>
    <div class="ui-dialog-buttonpane d-flex justify-content-around col-12 mt-4" *ngIf="isShowForConfirmEmaillbl">
        <button type="button" class="btn btn-secondary" (click)="cancelEmailRequest()">
            {{ emailChangeString.email_cancel_btn_lbl | translate }}
        </button>
        <button type="button" class="btn btn-primary" (click)="confirmEmailRequest()">
            {{ emailChangeString.email_change_btn_lbl | translate }}
        </button>
    </div>
    <div class="ui-dialog-buttonpane text-center mt-4" *ngIf="!isShowForConfirmEmaillbl">
        <button type="button" class="btn btn-primary" (click)="cancelEmailRequest()">
            {{ emailChangeString.email_change_btn_lbl | translate }}
        </button>
    </div>
    <div class="ui-dialog-buttonpane text-center mt-2" *ngIf="!isShowForConfirmEmaillbl">
        <a href="{{ wgCloudLink }}">{{ errorCompStrs.return_to_wg_cloud | translate }}</a>
    </div>

</ng-container>

<ng-container *ngIf="isShowConfirmation">
    <div class="text-center mt-4">
        <h4>{{ emailChangeString.confirm_email_success | translate }}</h4>
    </div>
    <div class="text-center mt-4">
        <wg-button color="primary" (click)="returnToCloudLogin()">
            {{ addAccCompStrs.return_to_login_page_btn_lbl | translate }}
        </wg-button>
    </div>
</ng-container>
<ng-container *ngIf="isShowCancelConfirmation">
    <div class="text-center mt-4">
        <h4>{{ emailChangeString.cancel_email_message | translate }}</h4>
    </div>
    <div class="text-center mt-4">
        <wg-button color="primary">
            <a class="btn-a" href="{{ signInUrl }}">{{ addAccCompStrs.return_to_login_page_btn_lbl | translate }}</a>
        </wg-button>
    </div>
</ng-container>