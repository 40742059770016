import { Component, OnInit , OnDestroy, ElementRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, ValidatorFn, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';

import { Observable, Subscription } from 'rxjs';

import { WgDropdownItem } from '@watchguard/wg-dropdown';
import { NotificationType, WgNotificationsService } from '@watchguard/wg-notifications';
import { LoaderService } from '@watchguard/wg-loader';

import { ADD_NEW_ACC_STRINGS, APP_TITLE_STRINGS, ERROR_COMP_STRINGS, ERROR_STRINGS } from '../shared/ui-strings';
import { AccountManagerService } from '../account-manager.service';
import { CustomValidatorService } from '../shared/custom-validator.service';
import {
  ApiResponse,
  CreateAccountData,
  ErrorContent,
  GetAccountInfoByContactIdResponse,
  IndustryList,
  TranslationParam,
  UrlData,
  UserExistsApiResponse } from '../shared/model';
import { countriesList } from '../shared/countriesList';
import { CommonHelperService } from '../shared/common-helper.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'wgc-create-account',
  templateUrl: './create-account.component.html'
})
export class CreateAccountComponent implements OnInit, OnDestroy{

  isShowConfirmation: boolean = false;
  isShowOptInEmail: boolean = false;
  isShowWarning: boolean = false;
  isShowCreateAccountForm: boolean = false;
  isInviteUserinNewAccountForm: boolean = false;
  isInviteUserForm: boolean = false;
  form: UntypedFormGroup;
  contactId: string;
  accountInfo: GetAccountInfoByContactIdResponse;

  compStrs = ADD_NEW_ACC_STRINGS;
  errorStrs = ERROR_STRINGS;
  titleStrs = APP_TITLE_STRINGS;

  industryList: WgDropdownItem[] = [];
  countryList: WgDropdownItem[] = [];
  stateList: WgDropdownItem[] = [];

  industrySelected!: WgDropdownItem;
  countrySelected!: WgDropdownItem;
  stateSelected: WgDropdownItem;

  checkUserNameExistsSub: Subscription;
  createAccountSub: Subscription;
  checkEmailExistsSub: Subscription;
  getAccountInfoSub: Subscription;
  fetchQueryParamsSub: Subscription;

  userNameExistsValFn: ValidatorFn = this.customValidator.commonValFn('userNameUnique');
  stateRequiredFn: ValidatorFn = this.customValidator.requiredFn('state');

  emailTransParams: TranslationParam = { email: '' };
  errorCompStrs = ERROR_COMP_STRINGS;

  setError: ErrorContent = {
    errorHeader: this.translateService.instant(this.errorCompStrs.page_heading),
    errorMessage: this.translateService.instant(this.errorCompStrs.duplicate_email_error_heading),
    redirectMessage: this.translateService.instant(this.errorCompStrs.duplicate_email_error_subheading)
  };  

  constructor(
    private fb: UntypedFormBuilder,
    private customValidator: CustomValidatorService,
    private accountManagerService: AccountManagerService,
    private notificationService: WgNotificationsService,
    private commonHelperService: CommonHelperService,
    private loaderService: LoaderService,
    private el: ElementRef,
    private route: ActivatedRoute,
    private translateService: TranslateService
    ) {
  }

  ngOnInit(): void {
    this.commonHelperService.setTitle(this.titleStrs.create_account);
    this.setUserFlow();
  }

  setUserFlow(): void {
    const urlData: UrlData = { params: {} };
    this.fetchQueryParamsSub = this.fetchQueryParams().subscribe((queryParams: Params) => {

      urlData.params = queryParams;
      this.contactId = urlData.params.contact_id;

      if (this.contactId === undefined) {
        this.isShowCreateAccountForm = true;
        this.initForm();
        this.setDropDownItems();
      } else {
        this.loaderService.showLoading();
        this.getAccountInfoByContactId();
      }
    });
  }

  fetchQueryParams(): Observable<Params> {
    return this.route.queryParams;
  }

  getAccountInfoByContactId(): void {
    this.getAccountInfoSub = this.accountManagerService.getAccountInfoByContactId(this.contactId)
    .subscribe({
      next: (data: GetAccountInfoByContactIdResponse) => this.handleGetAccountInfoByContactIdResponse(data),
      error: () => this.commonHelperService.handleApiError()
    });
  }

  handleGetAccountInfoByContactIdResponse(data: GetAccountInfoByContactIdResponse): void {
    if (data.status === 'success') {
      this.accountInfo = data;
      this.setInviteFromSalesforceFlow(this.accountInfo.account_exists);
    } else {
      this.commonHelperService.showApiError();
    }

    this.loaderService.hideLoading();
  }

  setInviteFromSalesforceFlow(account_exists: boolean): void {
    if (!account_exists) {
      this.isInviteUserinNewAccountForm = true;
    } else {
      if (this.accountInfo.account_details.isEmailDuplicateInSameAccount) {
        this.commonHelperService.showApiError(this.setError);
      }
      else {
        this.isInviteUserForm = true;
      }
    }
  }

  initForm(): void {
    this.form = this.fb.group({
      firstName: [
        '',
        [
          this.customValidator.requiredFn('firstName'),
          this.customValidator.regexFn('firstName'),
          this.customValidator.lengthValFn('firstName', 40)
        ]
      ],
      lastName: [
        '',
        [
          this.customValidator.requiredFn('lastName'),
          this.customValidator.regexFn('lastName'),
          this.customValidator.lengthValFn('lastName', 40)
        ]
      ],
      userName: [
        '',
        [
          this.customValidator.requiredFn('userName'),
          this.customValidator.regexFn('userName'),
          this.customValidator.lengthValFn('userName', 65, 5)
        ]
      ],
      email: [
        '',
        [
          this.customValidator.requiredFn('email'),
          this.customValidator.regexFn('email'),
          this.customValidator.lengthValFn('email', 80, 4)
        ]
      ],
      phone: [
        '',
        [
          this.customValidator.requiredFn('phone'),
          this.customValidator.regexFn('phone'),
          this.customValidator.lengthValFn('phone', 40, 6)
        ]
      ],
      companyName: [
        '',
        [
          this.customValidator.requiredFn('companyName'),
          this.customValidator.regexFn('companyName')
        ]
      ],
      industry: [null, this.customValidator.requiredFn('industry')],
      street: [
        '',
        [
          this.customValidator.requiredFn('street'),
          this.customValidator.regexFn('street'),
          this.customValidator.lengthValFn('streetAddress', 150)
        ]
      ],
      postalCode: [
        '',
        [
          this.customValidator.requiredFn('postalCode'),
          this.customValidator.regexFn('postalCode'),
          this.customValidator.lengthValFn('postalCode', 20)
        ]
        ],
      city: [
        '',
        [
          this.customValidator.requiredFn('city'),
          this.customValidator.regexFn('city'),
          this.customValidator.lengthValFn('city', 40)
        ]
      ],
      state: [null],
      country: [null, [this.customValidator.requiredFn('country')]],
      optedInForEmail: [false]
    });
  }

  setDropDownItems(): void {
    Object.values(IndustryList)
    .forEach((industry: IndustryList) => this.industryList.push({ id: industry, val: industry }));

    Object.keys(countriesList)
    .forEach((country: string) => this.countryList.push({ id: country, val: country }));
  }

  updateStateList(): void {
    const countryInfo = countriesList[this.countrySelected.val];
    const stateField: AbstractControl = this.form.controls.state;

    this.isShowOptInEmail = countryInfo.zone === 'EU';

    this.stateList = countryInfo.states;

    this.stateSelected = null;
    stateField.setValue(null);

    if (this.stateList.length) {
      stateField.markAsUntouched();
      stateField.addValidators([this.stateRequiredFn]);
    } else {
      stateField.removeValidators([this.stateRequiredFn]);
    }

    stateField.updateValueAndValidity();
  }

  dropdownSelected(item: WgDropdownItem, field: string): void {
    if (field === 'industry') {
      this.industrySelected = item;
    } else if (field === 'country') {
      this.countrySelected = item;
      this.updateStateList();
    } else if (field === 'state') {
      // We will simply update userSelection reference only when it is null or selectedItem is different
      if (!this.stateSelected || item.id !== this.stateSelected.id) {
        this.stateSelected = item;
      }
    }
  }

  handleValidateUserNameResponse(data: UserExistsApiResponse, userNameField: AbstractControl): void {
    if (data.user_exists) {
      userNameField.addValidators([this.userNameExistsValFn]);
      this.loaderService.hideLoading();
      this.commonHelperService.getFormFieldElementRef(this.el, 'userName', '[formcontrolname="userName"]').focus();
    } else {
      userNameField.removeValidators([this.userNameExistsValFn]);
      this.validateDuplicateEmail();
    }

    userNameField.updateValueAndValidity();
  }

  updateUsernameFieldValidity(): void {
    const userNameField: AbstractControl = this.form.controls.userName;
    userNameField.removeValidators([this.userNameExistsValFn]);
    userNameField.updateValueAndValidity();
  }

  validateUserNameExists(): void {
    const userName = this.form.value.userName || '';
    const userNameField: AbstractControl = this.form.controls.userName;

    if (userNameField.valid) {

      this.checkUserNameExistsSub = this.accountManagerService.checkUserNameExists(userName)
      .subscribe({
        next: (data: UserExistsApiResponse) => this.handleValidateUserNameResponse(data, userNameField),
        error: () => this.commonHelperService.handleApiError()
      });

    } else {
      userNameField.removeValidators([this.userNameExistsValFn]);
      userNameField.updateValueAndValidity();
    }
  }

  handleValidateEmailResponse(data: UserExistsApiResponse, email: string): void {
    this.notificationService.clear(NotificationType.Info);
    this.emailTransParams = { email };

    if (data.user_exists) {
      this.showDuplicateEmailWarningDialog();
    } else {
      this.createAccount();
    }

    this.loaderService.hideLoading();
  }

  createAccountWithDuplicateEmail(): void {
    this.isShowWarning = false;
    this.loaderService.showLoading();
    this.createAccount();
  }

  validateDuplicateEmail(): void {
    const email = this.form.value.email || '';

    if (email) {
      this.loaderService.showLoading();

      this.checkEmailExistsSub = this.accountManagerService.checkDuplicateEmail(email)
      .subscribe({
        next: (data: UserExistsApiResponse) => this.handleValidateEmailResponse(data, email),
        error: () => this.commonHelperService.handleApiError()
      });
    }
  }

  generatePayload(): CreateAccountData {
    const formData: CreateAccountData = {
      ...this.form.value,
      industry: this.industrySelected.val,
      country: this.countrySelected.val,
      state: this.stateSelected?.id || '',
      optedInForEmail: !this.form.value.optedInForEmail
    };

    return formData;
  }

  handleCreateAccountResponse(data: ApiResponse): void {
    if (data.status === 'success') {
      this.isShowConfirmation = true;
    } else {
      this.commonHelperService.showApiError();
    }
    this.loaderService.hideLoading();
  }

  createAccount(): void {
    const payload: CreateAccountData = this.generatePayload();

    this.createAccountSub = this.accountManagerService.createAccount(payload)
    .subscribe({
      next: (data: ApiResponse) => this.handleCreateAccountResponse(data),
      error: () => this.commonHelperService.handleApiError()
    });
  }

  closeDuplicateEmailWarningDialog(): void {
    this.isShowWarning = false;
    this.loaderService.hideLoading();
  }

  showDuplicateEmailWarningDialog(): void {
    this.isShowWarning = true;
  }

  submit(): void {
    if (this.form.valid) {
      this.loaderService.showLoading();
      this.validateUserNameExists();
    } else {
      this.form.markAllAsTouched();
      this.commonHelperService.setFocusOnInvalidFormField(this.form, this.el);
    }
  }

  ngOnDestroy(): void {
    this.checkUserNameExistsSub?.unsubscribe();
    this.checkEmailExistsSub?.unsubscribe();
    this.createAccountSub?.unsubscribe();
    this.getAccountInfoSub?.unsubscribe();
    this.fetchQueryParamsSub?.unsubscribe();
  }

}
