import { OnDestroy, OnInit , Component, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';

import { LoaderService } from '@watchguard/wg-loader';

import { AccountManagerService } from '../account-manager.service';
import { CommonHelperService } from '../shared/common-helper.service';
import { CustomValidatorService } from '../shared/custom-validator.service';
import { ApiResponse } from '../shared/model';
import { APP_TITLE_STRINGS, ERROR_STRINGS, RESETS_PASSWORD_STRINGS } from '../shared/ui-strings';


@Component({
  selector: 'wgc-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit, OnDestroy{

  requestSubmitted = false;
  form: UntypedFormGroup;

  compStrs = RESETS_PASSWORD_STRINGS;
  errorStrs = ERROR_STRINGS;
  titleStrs = APP_TITLE_STRINGS;

  resetPasswordSub: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private customValidator: CustomValidatorService,
    private accountManagerService: AccountManagerService,
    private commonHelperService: CommonHelperService,
    private loaderService: LoaderService,
    private el: ElementRef) {
  }

  ngOnInit(): void {
    this.commonHelperService.setTitle(this.titleStrs.forgot_password);
    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group({
      userName: [
        '',
        [
          this.customValidator.requiredFn('userName'),
          this.customValidator.lengthValFn('userName', 65, 5)
        ]
      ]
    });
  }

  handleApiResponse(data: ApiResponse): void {
    this.loaderService.hideLoading();

    if (data.status === 'success') {
        this.requestSubmitted = true;
    } else {
      this.commonHelperService.showApiError();
    }
  }

  sendResetPasswordEmail(): void {
    const userName = this.form.value.userName;

    this.resetPasswordSub = this.accountManagerService.sendResetPassword({ userName })
    .subscribe({
      next: (data: ApiResponse) => this.handleApiResponse(data),
      error: () => this.commonHelperService.handleApiError()
    });
  }

  submit(): void {
    if (this.form.valid) {
      this.loaderService.showLoading();
      this.sendResetPasswordEmail();
    } else {
      this.form.markAllAsTouched();
      this.commonHelperService.setFocusOnInvalidFormField(this.form, this.el);
    }
  }

  ngOnDestroy(): void {
    this.resetPasswordSub?.unsubscribe();
  }

}
